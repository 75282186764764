<template>
  <div>
    <div class="lg:hidden">
      <carousel
        :autoplay="true"
        :loop="true"
        :responsive="{
          0: { items: 1, nav: false },
        }"
      >
        <div v-for="(item, i) in banner" :key="i">
          <div class="relative">
            <img :src="item.image" :alt="item.alt" class="" />
            <div class="absolute inset-x-0 bottom-0 h-14">
              <div class="container">
                <a
                  v-if="!hideEnquiry"
                  href="#"
                  id="btn-enquire"
                  v-scroll-to="{ el: '#element', duration: 1500 }"
                >
                  <h1
                    class="w-48 py-2 mx-auto text-base font-bold text-white rounded-md bg-uni-red"
                  >
                    Request E-Brochure
                  </h1>
                </a>
              </div>
            </div>
          </div>
        </div>
      </carousel>
    </div>

    <!-- desktop -->
    <div class="hidden lg:block">
      <carousel
        :autoplay="true"
        :loop="true"
        :responsive="{
          0: { items: 1, nav: false },
        }"
      >
        <div v-for="(item, i) in banner_desktop" :key="i">
          <div class="relative">
            <img :src="item.image" :alt="item.alt" class="" />
            <div class="absolute inset-x-0 bottom-0 h-14">
              <div class="container">
                <a
                  v-if="!hideEnquiry"
                  href="#"
                  id="btn-enquire"
                  v-scroll-to="{ el: '#element', duration: 1500 }"
                >
                  <h1
                    class="w-48 py-2 mx-auto text-base font-bold text-white rounded-md bg-uni-red"
                  >
                    Request E-Brochure
                  </h1>
                </a>
              </div>
            </div>
          </div>
        </div>
      </carousel>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
export default {
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    carousel,
  },
  data() {
    return {
      banner: [
        {
          image: "/images/mobile-1.jpg",
          alt: "banner-mobile",
        },
        {
          image: "/images/mobile-2.jpg",
          alt: "banner-mobile",
        },
        {
          image: "/images/mobile-3.jpg",
          alt: "banner-mobile",
        },
        {
          image: "/images/mobile-4.jpg",
          alt: "banner-mobile",
        },
      ],
      banner_desktop: [
        {
          image: "/images/desktop-1.jpg",
          alt: "banner-desktop",
        },
        {
          image: "/images/desktop-2.jpg",
          alt: "banner-desktop",
        },
        {
          image: "/images/desktop-3.jpg",
          alt: "banner-desktop",
        },
      ],
    };
  },
};
</script>

<style>
</style>