<template>
  <div>
    <div class="h-1 bg-black"></div>
    <!-- header -->
    <MainHeader />

    <!-- Main Banner -->
    <!-- mobile -->
    <MainBanner />

    <!-- product section -->
    <div class="py-10">
      <div class="container">
        <h1 class="pb-4 text-xl font-bold text-center">Car Models</h1>
        <div class="h-1 mx-auto bg-uni-red w-7"></div>
        <div class="flex flex-wrap md:justify-center">
          <div
            v-for="(item, i) in cars"
            :key="i"
            class="w-1/2 px-1 pt-5 md:w-1/4 lg:w-1/5 lg:px-2"
          >
            <div class="py-4 hover">
              <img :src="item.image" :alt="item.alt" class="" />
              <h1 class="text-base font-bold text-center">{{ item.alt }}</h1>
              <h1 class="text-base font-bold text-center">
                From <span class="text-uni-red">{{ item.price }}</span>
              </h1>
              <div class="pt-2">
                <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }">
                  <h1
                    class="w-32 py-1 mx-auto text-sm text-white bg-gray-900 rounded-md md:w-36"
                  >
                    Request Brochure
                  </h1>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- call btn -->
        <div class="pt-5">
          <a href="tel:+60126236841">
            <h1
              class="w-64 px-1 py-2 mx-auto text-base font-bold text-white rounded-md bg-uni-red"
            >
              Speak to Our Sales Advisor
            </h1>
          </a>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <!-- <div class="">
      <div class="lg:flex lg:items-center bg-uni-red">
        <div class="lg:w-1/2">
          <img src="/images/choose-bg.jpg" alt="why choose us" class="" />
        </div>
        <div class="bg-uni-red lg:w-1/2">
          <div class="py-5">
            <h1 class="pb-4 text-xl font-bold text-center text-white">
              Why Choose Us
            </h1>
            <div class="h-1 mx-auto bg-white w-7"></div>
            <div class="pt-6 pl-6 md:pl-0 md:max-w-xs md:mx-auto">
              <ul class="text-lg font-semibold text-left text-white">
                <li>
                  <i
                    class="inline-block w-10 pr-2 text-2xl align-middle fas fa-car"
                  ></i>
                  Good Product Knowledge
                </li>
                <li>
                  <i
                    class="inline-block w-10 pr-2 text-2xl align-middle fas fa-landmark"
                  ></i>
                  Car Loan Expert
                </li>
                <li>
                  <i
                    class="inline-block w-10 pr-2 text-2xl align-middle fas fa-percentage"
                  ></i>
                  Low Interest Rate
                </li>
                <li>
                  <i
                    class="inline-block w-10 pr-2 text-2xl align-middle fas fa-recycle"
                  ></i>
                  High Trade in Price
                </li>
                <li>
                  <i
                    class="inline-block w-10 pr-2 text-2xl align-middle fas fa-door-open"
                  ></i>
                  Door to Door Service Test Drive
                </li>
                <li>
                  <i
                    class="inline-block w-10 pr-2 text-2xl align-middle fas fa-calendar-alt"
                  ></i>
                  5 Years Warranty
                </li>
              </ul>
            </div>
            <div class="pt-6">
              <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }">
                <h1
                  class="w-56 py-2 mx-auto text-base font-medium text-white bg-gray-900 rounded-md"
                >
                  Call Us : 012-6236841
                </h1>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="py-10 bg-uni-red">
      <div class="container">
        <h1 class="pb-4 text-xl font-bold text-center text-white">
          Why Choose Us
        </h1>
        <div class="h-1 mx-auto bg-white w-7"></div>

        <div
          class="flex flex-wrap pt-6 xl:max-w-4xl xl:mx-auto md:justify-center"
        >
          <div
            v-for="(item, i) in choose"
            :key="i"
            class="w-1/2 p-2 lg:w-1/5 md:w-1/3"
          >
            <img :src="item.image" :alt="item.alt" class="w-24 mx-auto" />
            <h1 class="pt-3 font-bold text-white text-bases">{{ item.alt }}</h1>
          </div>
        </div>
        <div class="pt-4 md:flex md:justify-center">
          <a href="tel:+60126236841" class="block px-1 pb-1">
            <h1
              class="p-2 mx-auto font-semibold text-gray-700 bg-white rounded-md hover:bg-gray-100 w-60"
            >
              Speak to Our Sales Advisor
            </h1>
          </a>
          <a
            href="#"
            id="btn-enquire"
            v-scroll-to="{ el: '#element', duration: 1500 }"
            class="block px-1"
          >
            <h1
              class="p-2 mx-auto font-semibold text-gray-700 bg-white rounded-md hover:bg-gray-100 w-60"
            >
              Book Your Test Drive Today!
            </h1>
          </a>
        </div>
      </div>
    </div>

    <!-- cash rebate -->
    <div class="pt-4">
      <img src="/images/hot-deal-4.jpg" alt="" class="mx-auto">
    </div>
    <!-- Promotion -->
    <div>
      <div class="container py-10">
        <h1 class="pb-4 text-xl font-bold text-center">Hot Deals</h1>
        <div class="h-1 mx-auto bg-uni-red w-7"></div>
        <carousel
          :autoplay="true"
          :loop="true"
          :responsive="{
            0: { items: 1, nav: false },
          }"
        >
          <!-- deals -->
          <div class="pt-5">
            <div class="lg:w-2/3 lg:mx-auto">
              <img src="/images/hot-deal-1.jpg" alt="" class="" />
              <img src="/images/plan.png" alt="" class="py-4" />
            </div>
            <div class="pt-4">
              <h1 class="text-xl font-bold text-uni-red">Benefits</h1>
              <div class="w-3/4 mx-auto lg:max-w-2xl">
                <ul
                  class="pt-3 text-base font-semibold text-left text-gray-900 list-disc "
                >
                  <li class="pb-1">
                    Lowest and competitive monthly instalment for the first 3
                    years
                  </li>
                  <li class="pb-1">
                    Slight increase in Tier 2 (Year 4 - 6) in line with your
                    career progression
                  </li>
                  <li class="pb-1">
                    Flexible option for Tier 3 (Year 7 - 9): continue repayment
                    OR trade-in for a new Toyota
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!-- 2nd deal -->
          <div class="pt-5">
            <div class="lg:w-2/3 lg:mx-auto">
              <img src="/images/hot-deal-2.jpg" alt="" class="" />
              <img src="/images/hot-deal-3.jpg" alt="" class="py-4" />
            </div>
            <div class="pt-4">
              <h1 class="text-xl font-bold text-uni-red">Benefits</h1>
              <p
                class="pt-3 pl-4 text-base font-semibold text-left text-gray-900 lg:text-center"
              >
                Jom Drive gets you out and driving in 1-2-3 with easy & hassle
                free application! Special benefits for government employees that
                apply for Jom Drive.
              </p>
            </div>
          </div>
        </carousel>
      </div>
    </div>

    <!-- enquire form -->
    <div
      id="element"
      class="bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/enquiry-bg.jpg')"
    >
      <div class="container py-10">
        <div class="pb-8 text-center">
          <h1 class="pb-3 text-xl font-bold leading-tight text-white">
            Send an Enquiry
          </h1>
          <p class="text-base font-semibold text-white">
            Fill out the form below and we'll get back to you as soon as
            possible
          </p>
        </div>
        <!-- feedback.activamedia.com.sg script begins here -->
        <div id="enquiry" class="lg:w-2/3 lg:mx-auto">
          <iframe
            allowTransparency="true"
            style="min-height: 580px; height: inherit; overflow: auto"
            width="100%"
            id="contactform123"
            name="contactform123"
            marginwidth="0"
            marginheight="0"
            frameborder="0"
            src="https://feedback.activamedia.com.sg/my-contact-form-5921930.html"
            ><p>
              Your browser does not support iframes. The contact form cannot be
              displayed. Please use another contact method (phone, fax etc)
            </p></iframe
          >
        </div>
        <!-- feedback.activamedia.com.sg script ends here -->
      </div>
    </div>

    <!-- footer -->
    <div class="py-8">
      <div class="container">
        <div>
          <img
            src="/images/main-logo.png"
            alt="Unihub Auto Sdn Bhd"
            class="w-1/2 mx-auto md:w-40"
          />
          <div class="py-4">
            <p class="text-lg font-semibold text-center">
              23, Persiaran Puchong Jaya Selatan, Bandar Puchong Jaya, 47100
              Puchong, Selangor
            </p>
            <div class="pt-5 text-gray-900">
              <p class="text-lg font-semibold text-center">
                Call Us At :
                <a href="tel:+60126236841">012-6236841</a>
              </p>
              <p class="text-lg font-semibold text-center">
                Whatsapp Us :
                <a href="https://wa.me/60126236841">012-6236841</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Copyright />
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import carousel from "vue-owl-carousel";
import MainHeader from "@/components/MainHeader.vue";
import MainBanner from "@/components/MainBanner.vue";
import Copyright from "@/components/Copyright.vue";

export default {
  name: "Home",
  components: {
    MainHeader,
    MainBanner,
    Copyright,
    carousel,
  },
  data() {
    return {
      cars: [
        { image: "/images/car-1.png", alt: "Vios", price: "RM74,623*" },
        { image: "/images/car-2.png", alt: "Yaris", price: "RM70,940*" },
        { image: "/images/car-3.png", alt: "Corolla", price: "RM123,972*" },
        { image: "/images/car-4.png", alt: "Camry", price: "RM188,763*" },
        { image: "/images/car-5.png", alt: "Rush", price: "RM88,314*" },
        {
          image: "/images/car-6.png",
          alt: "Corolla Cross",
          price: "RM124,000*",
        },
        { image: "/images/car-7.png", alt: "RAV4", price: "RM215,665*" },
        { image: "/images/car-8.png", alt: "Avanza", price: " RM77,963*" },
        { image: "/images/car-9.png", alt: "Fortuner", price: "RM172,244*" },
        { image: "/images/car-10.png", alt: "Inova", price: "RM111,622*" },
        { image: "/images/car-11.png", alt: "Harrier", price: "RM249,706*" },
        { image: "/images/car-12.png", alt: "Alphard", price: "RM446,609*" },
        { image: "/images/car-13.png", alt: "Corolla Cross Hybrid", price: "RM137,000*" },
        { image: "/images/car-14.png", alt: "Hilux", price: "RM92,880*" },
        { image: "/images/car-15.png", alt: "Hiace", price: "RM103,000*" },
      ],
      choose: [
        { image: "/images/choose-1.png", alt: "Car Loan Expert" },
        { image: "/images/choose-2.png", alt: "Low Interest Rate" },
        { image: "/images/choose-3.png", alt: "High Trade in Price" },
        {
          image: "/images/choose-4.png",
          alt: "Door to Door Service Test Drive",
        },
        { image: "/images/choose-5.png", alt: "5 Years Warranty" },
      ],
    };
  },
};
</script>
<style>
.hover {
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}
.hover:hover {
  -moz-box-shadow: 0 0 10px #ccc;
  -webkit-box-shadow: 0 0 10px #ccc;
  box-shadow: 0 0 10px #ccc;
}

@media (min-width: 768px) {
  #enquiry iframe {
    min-height: 540px !important;
  }
}
</style>