<template>
  <header class="py-2 lg:py-4">
      <div class="container">
        <div class="flex items-center justify-between">
          <img
            src="/images/main-logo.png"
            alt="Unihub Auto Sdn Bhd"
            class="w-20 lg:w-32"
          />
          <div class="text-right">
            <h1 class="pb-1 text-base font-bold lg:text-xl">Call Us:</h1>
            <div>
              <a href="tel:+60126236841" id="call-btn-top">
              <h1 class="px-2 py-1 text-white rounded-lg lg:text-xl lg:px-3 bg-uni-red">
                012-6236841
              </h1>
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
</template>

<script>
export default {

}
</script>

<style>

</style>