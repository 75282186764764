<template>
  <div class="container">
    <div class="py-3 bg-uni-red">
      <p class="font-sans text-sm text-center text-white">
        Website maintained by Activa Media. All Rights Reserved.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>